import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {GlobalConstants} from '../global-constants';
import {EmployeeDetails, Employment, FederalTax, StateTax} from './master.entities.models';
import {Observable, throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterEmployeeService {

  constructor(private http: HttpClient) { }

  public getAll() {
    return this.http.get(`${GlobalConstants.apiURL}/api/employees/`);
  }

  public getEmployeeDetails(id: number): Observable<EmployeeDetails> {
    return this.http.get<EmployeeDetails>(`${GlobalConstants.apiURL}/api/employees/${id}`);
  }

  public getEmployments(id: number): Observable<Array<Employment>> {
    return this.http.get<Array<Employment>>(`${GlobalConstants.apiURL}/api/employees/${id}/employments`)
  }
}
