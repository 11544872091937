<mat-drawer-container autosize style="background: white">

  <mat-drawer #drawer [mode]="'side'" position="end">
    <div class="container">
      <h3 class="padding-top-15">Filters</h3>
      <mat-divider></mat-divider>
      <mat-form-field class="padding-top-15">
        <mat-label>Location</mat-label>
        <mat-select [(ngModel)]="filters.location.value" (selectionChange)="updateFilter()">
          <mat-option value="">All locations</mat-option>
          <mat-option *ngFor="let location of filters.location.options"
                      [value]="location.id">{{location.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <mat-form-field class="padding-top-15">
        <mat-label>Department</mat-label>
        <mat-select [(ngModel)]="filters.department.value" (selectionChange)="updateFilter()">
          <mat-option value="">All departments</mat-option>
          <mat-option *ngFor="let department of filters.department.options"
                      [value]="department.id">{{department.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <mat-form-field class="padding-top-15">
        <mat-label>Position</mat-label>
        <mat-select [(ngModel)]="filters.position.value" (selectionChange)="updateFilter()">
          <mat-option value="">All positions</mat-option>
          <mat-option *ngFor="let position of filters.position.options"
                      [value]="position.id">{{position.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <mat-form-field class="padding-top-15">
        <mat-label>Job Title</mat-label>
        <mat-select [(ngModel)]="filters.jobTitle.value" (selectionChange)="updateFilter()">
          <mat-option value="">All job titles</mat-option>
          <mat-option *ngFor="let jobTitle of filters.jobTitle.options"
                      [value]="jobTitle.id">{{jobTitle.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <mat-form-field class="padding-top-15">
        <mat-label>Employee Type</mat-label>
        <mat-select [(ngModel)]="filters.employeeType.value" (selectionChange)="updateFilter()">
          <mat-option value="">All employee types</mat-option>
          <mat-option *ngFor="let employeeType of filters.employeeType.options"
                      [value]="employeeType.id">{{employeeType.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <mat-form-field class="padding-top-15">
        <mat-label>Employee Status</mat-label>
        <mat-select [(ngModel)]="filters.employeeStatus.value" (selectionChange)="updateFilter()">
          <mat-option value="">All employee statuses</mat-option>
          <mat-option *ngFor="let employeeStatus of filters.employeeStatus.options"
                      [value]="employeeStatus.id">{{employeeStatus.name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <button (click)="drawer.toggle()" mat-stroked-button>
        <mat-icon aria-hidden="false">close</mat-icon>
        Close
      </button>&nbsp;
      <button (click)="resetGrid()" mat-stroked-button color="accent">
        <mat-icon aria-hidden="false">refresh</mat-icon>
        Reset
      </button>
    </div>
  </mat-drawer>

  <mat-drawer-content style="padding-top: 15px">
    <h5 class="card-title text-muted">Employee Directory</h5>
    <mat-divider></mat-divider>

    <form class="form-inline list-employee-search-header">
      <div class="row">
        <div class="col col-lg-4">
          <mat-form-field class="toast-top-full-width" appearance="standard">
            <mat-label>Search by employee ID or name</mat-label>
            <input type="text"
                   placeholder=""
                   aria-label="Search"
                   matInput
                   (input)="updateFilter()" [(ngModel)]="filters.searchQuery.value">
          </mat-form-field>
        </div>
        <div class="col col-lg-2">
          <button mat-icon-button color="primary" (click)="drawer.toggle()" class="margin-top-15">
            <mat-icon aria-hidden="false">tune</mat-icon>
            <span *ngIf="calculateActiveFiltersCount() > 0" class="badge badge-small">{{calculateActiveFiltersCount()}}</span>
          </button>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>

    <div class="table-container">
      <div class="table-loading-shade"
           *ngIf="isLoadingResults">
        <mat-spinner [diameter]="50" *ngIf="isLoadingResults"></mat-spinner>
      </div>

      <div class="table-box">
        <table mat-table [dataSource]="dataSource"
               matSort matSortActive="id" matSortDisableClear matSortDirection="desc">

          <ng-container matColumnDef="rarebreedId" sticky>
            <th mat-header-cell *matHeaderCellDef>Rarebreed ID</th>
            <td mat-cell *matCellDef="let row">
            <span *ngIf="row">
              {{row.rarebreedId}}
            </span>
              <mat-icon *ngIf="!row.rarebreedId" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Internal ID</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.internalId">
                {{row.internalId}}
              </span>
              <mat-icon *ngIf="!row.internalId" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="firstName,lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.name">
                {{row.name}}
              </span>
              <mat-icon *ngIf="!row.name" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="employments.location.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Location</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.location" [innerHTML]="row.location">
              </span>
              <mat-icon *ngIf="!row.location" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="employments.jobTitle.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.jobTitle" [innerHTML]="row.jobTitle">
              </span>
              <mat-icon *ngIf="!row.jobTitle" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="employments.position.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Position</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.position" [innerHTML]="row.position">
              </span>
              <mat-icon *ngIf="!row.position" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="employments.department.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Department</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.department" [innerHTML]="row.department">
              </span>
              <mat-icon *ngIf="!row.department" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="employments.employeeType.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Type</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.employeeType" [innerHTML]="row.employeeType">
              </span>
              <mat-icon *ngIf="!row.employeeType" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="employments.employeeStatus.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee Status</th>
            <td mat-cell *matCellDef="let row">
              <span *ngIf="row.employeeStatus" [innerHTML]="row.employeeStatus">
              </span>
              <mat-icon *ngIf="!row.employeeStatus" class="font-18">remove</mat-icon>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row (click)="editEmployee(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
      <mat-paginator [length]="resultsLength" [pageSize]="pageSize"
                     [pageSizeOptions]="[10, 25, 100]"></mat-paginator>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
