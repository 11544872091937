import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  SimpleChanges
} from '@angular/core';
import {Action, Employment, EmploymentEvent, MasterEntities} from '../../master.entities.models';
import {compareById, compareYesNoBoolean} from '../../utils';
import {FormControl} from '@angular/forms';
import {Employee} from '../../list/employee-list.model';
import {AuthenticationService} from '../../../login/auth.service';

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css']
})
export class EmploymentComponent implements OnInit, OnChanges {

  @Input() employment: Employment;
  @Input() employee: Employee;
  @Input() masterEntities: MasterEntities;
  @Output() employmentEventEmitter = new EventEmitter<EmploymentEvent>();
  hiredOn = new FormControl();
  effectiveOn = new FormControl();
  terminatedOn = new FormControl();
  benefitClassEffectiveDate = new FormControl();

  constructor(public authService: AuthenticationService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.employment) {
      this.hiredOn.setValue(this.employment.hiredOn);
      this.effectiveOn.setValue(this.employment.effectiveOn);
      this.terminatedOn.setValue(this.employment.terminatedOn);
      this.benefitClassEffectiveDate.setValue(this.employment.benefitClassEffectiveDate);
    }
  }

  public compareOption(item1, item2) {
    return compareById(item1, item2);
  }

  isNew() {
    return !this.employment?.id;
  }

  getEmploymentIndex() {
    return this.isNew() ? 'new' : this.employment.id;
  }

  public compareByBool(item1, item2): boolean {
    return compareYesNoBoolean(item1, item2);
  }
}

@Pipe({
  name: 'sortByName'
})
export class SortByNamePipe implements PipeTransform {
  transform(value: any[]): any[] {
    return value != null ? value.sort((a, b) => {
      let result = 0;
      if (a.name < b.name) {
        result = - 1;
      } else if (a.name > b.name) {
        result = 1;
      }
      return result;
    }) : value;
  }
}
