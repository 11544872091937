import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UnmatchedRecord} from './unmatched-records.models';
import {GlobalConstants} from '../global-constants';
import {SortDirection} from '@angular/material/sort';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnmatchedRecordsService {

  constructor(private http: HttpClient) {
  }

  findAll(page: number, size: number, sort: string, order: SortDirection, search) {
    const sortParameter = sort ? `&sort=${sort},${order}` : '';
    const searchParameter = search ? `&search=${search}` : '';
    return this.http.get<Array<UnmatchedRecord>>(`${GlobalConstants.apiURL}/api/unmatched-employee?page=${page}&size=${size}${sortParameter}${searchParameter}`);
  }

  count(search) {
    const searchParameter = search ? `&search=${search}` : '';
    return this.http.get<Array<UnmatchedRecord>>(`${GlobalConstants.apiURL}/api/unmatched-employee/count?${searchParameter}`);
  }

  matchEmployee(employeeId, originalId, originTypeCode, recordType, sourceType): Observable<any> {
    const uriPath = this.defineUriPath(recordType, sourceType, null);
    const mapFromId = this.defineMapFromId(employeeId, originalId, recordType, null, null);
    const mapToId = this.defineMapToId(employeeId, originalId, recordType);
    return this.http.post(`${GlobalConstants.apiURL}/api/${uriPath}/${mapFromId}/origins/${originTypeCode}`, {originalId: mapToId});
  }

  unmatchEmployee(employeeId, originalId, originTypeCode, recordType, sourceType, mappedType, mappedId): Observable<any> {
    const uriPath = this.defineUriPath(recordType, sourceType, mappedType);
    const mapFromId = this.defineMapFromId(employeeId, originalId, recordType, mappedType, mappedId);
    return this.http.delete(`${GlobalConstants.apiURL}/api/${uriPath}/${mapFromId}/origins/${originTypeCode}`);
  }

  defineUriPath(recordType, sourceType, mappedType) {
    let result = 'undefined';
    if (recordType === 'VIRTUAL_EMPLOYEE') {
      result = 'virtual-employees';
    } else if (recordType === 'EMPLOYEE') {
      result = 'employees';
    } else if (recordType === 'NETSUITE_EMPLOYEE') {
      if (mappedType === 'EMPLOYEE') {
        result = 'employees';
      } else if (mappedType === 'VIRTUAL_EMPLOYEE') {
        result = 'virtual-employees';
      } else if (sourceType === 'EMPLOYEE') {
        result = 'employees';
      } else if (sourceType === 'VIRTUAL_EMPLOYEE') {
        result = 'virtual-employees';
      }
    }
    return result;
  }

  private defineMapFromId(employeeId, originalId, recordType, mappedType, mappedId) {
    let result = employeeId;
    if (mappedType) {
      result = mappedId;
    } else if (recordType === 'NETSUITE_EMPLOYEE') {
      result = originalId;
    }
    return result;
  }

  private defineMapToId(employeeId, originalId, recordType) {
    let result = originalId;
    if (recordType === 'NETSUITE_EMPLOYEE') {
      result = employeeId;
    }
    return result;
  }
}
