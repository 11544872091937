import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {EmployeeListService} from './employee-list.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Employee, EmployeesPage, GridRow, SearchFilters} from './employee-list.model';
import {merge, of as observableOf} from 'rxjs';
import {catchError, startWith, switchMap} from 'rxjs/operators';
import {EmployeeListFiltersService} from './employee-list.filters.service';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-listemployee',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements AfterViewInit {

  readonly LOCAL_STORAGE_FILTERS_SUFFIX = 'employees-01';
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  advancedSearchVisible = false;
  filters: SearchFilters;
  displayedColumns: string[] = ['rarebreedId', 'id', 'firstName,lastName', 'employments.location.name', 'employments.jobTitle.name',
    'employments.position.name', 'employments.department.name', 'employments.employeeType.name', 'employments.employeeStatus.name'];
  isLoadingResults = true;
  resultsLength = 0;
  pageSize = 25;
  data: GridRow[] = [];
  dataSource: MatTableDataSource<GridRow>;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private employeeListService: EmployeeListService,
              private filtersService: EmployeeListFiltersService) {
    this.initFilters();
  }

  private initFilters() {
    this.filters = this.filtersService.getFilters('employees');
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.employeeListService.getAll(this.paginator.pageIndex, this.paginator.pageSize,
            this.sort.active, this.sort.direction, this.filtersService.buildSearchParam(this.filters))
            .pipe(catchError(() => observableOf(null)));
        })
      )
      .subscribe((page: EmployeesPage) => {
        this.isLoadingResults = false;
        // @ts-ignore
        this.resultsLength = page?.totalElements;
        this.asDataSource(page);
      });
  }

  asDataSource(page: EmployeesPage) {
    this.data.splice(0, this.data.length);
    Array.prototype.push.apply(this.data, this.buildRows(page));
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.sort = this.sort;
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
  }

  private buildRows(employeesPage: EmployeesPage) {
    return employeesPage.content.map(employee => ({
      rarebreedId: employee.rarebreedId,
      internalId: employee.id,
      name: `${employee.firstName} ${employee.lastName}`,
      location: this.formatEmploymentValues(employee, (employment) => {
        return employment.location?.name
      }),
      jobTitle: this.formatEmploymentValues(employee, (employment) => {
        return employment.jobTitle?.name
      }),
      position: this.formatEmploymentValues(employee, (employment) => {
        return employment.position?.name
      }),
      department: this.formatEmploymentValues(employee, (employment) => {
        return employment.department?.name
      }),
      employeeType: this.formatEmploymentValues(employee, (employment) => {
        return employment.employeeType?.name
      }),
      employeeStatus: this.formatEmploymentValues(employee, (employment) => {
        return employment.employeeStatus?.name
      })
    }));
  }

  private joinDistinct(elements: Array<string>): string {
    return Array.from(new Set(elements)).join('<br>');
  }

  private getEmploymentFieldValues(employee: Employee, extractEmploymentField: (employment) => string): Array<string> {
    return employee.employments.filter(em => !em.deleted).map(extractEmploymentField);
  }

  private formatEmploymentValues(employee: Employee, extractEmploymentField: (employment) => string): string {
    return this.joinDistinct(this.getEmploymentFieldValues(employee, extractEmploymentField));
  }

  editEmployee(selectedElement: any) {
    this.router.navigate(['employees/details', selectedElement.internalId, {fullname: selectedElement.name}]);
  }

  showHideAdvancedSearch() {
    this.advancedSearchVisible = !this.advancedSearchVisible;
  }

  resetGrid() {
    this.filtersService.resetFilters(this.LOCAL_STORAGE_FILTERS_SUFFIX);
    this.initFilters();
    this.reset();
  }

  updateFilter() {
    this.filtersService.saveFilters(this.filters, this.LOCAL_STORAGE_FILTERS_SUFFIX);
    this.reset();
  }

  render(hasMismatches: boolean, newHire: boolean) {
    this.filters.hasMismatches.value = hasMismatches;
    this.filters.newHire.value = newHire;
    this.filtersService.saveFilters(this.filters, this.LOCAL_STORAGE_FILTERS_SUFFIX);
    this.reset();
  }

  calculateActiveFiltersCount() {
    let result = 0;
    for (const [key, value] of Object.entries(this.filters)) {
      if (value.value) {
        result++;
      }
    }
    return result;
  }
}
