import {AfterViewInit, Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatStepper} from '@angular/material/stepper';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Employee, EmployeesPage, GridPage, GridRow} from '../../../employee/list/employee-list.model';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {EmployeeListService} from '../../../employee/list/employee-list.service';
import {SelectionModel} from '@angular/cdk/collections';
import {UnmatchedRecordsService} from '../../unmatched-records.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {VirtualEmployeeService} from '../../../virtual-employee/virtual-employee.service';
import {VirtualEmployee, VirtualEmployeesPage} from '../../../virtual-employee/virtual-employee.models';
import {NetsuiteEmployeeService} from '../../../netsuite-employee/netsuite-employee.service';
import {NetsuiteEmployee, NetsuiteEmployeesPage} from '../../../netsuite-employee/netsuite-employee.models';

export interface DialogData {
  link: string,
  systemName: string,
  systemCode: string,
  content: string,
  searchQuery: string,
  originalId: string,
  fullName: string
  confirmRecordType: string
  selectedIndex: number,
  sourceType: string,
  unmap: boolean
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'match-dialog',
  templateUrl: 'match-dialog.html',
  styleUrls: ['match-dialog.css'],
})
// tslint:disable-next-line:component-class-suffix
export class MatchDialog implements AfterViewInit {
  constructor(
    public dialogRef: MatDialogRef<MatchDialog>,
    private employeeListService: EmployeeListService,
    public virtualEmployeeService: VirtualEmployeeService,
    public netsuiteEmployeeService: NetsuiteEmployeeService,
    public unmatchedRecordsService: UnmatchedRecordsService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private _snackBar: MatSnackBar) {
  }

  @ViewChild(MatStepper) stepper: MatStepper;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  confirmRecordType = 'EMPLOYEE';
  selectedIndex = 0;
  sourceType: string;
  displayedColumns: string[] = ['select', 'name'];
  displayedColumnsExt: string[] = ['select', 'name', 'mapped'];
  gridRows: GridRow[] = [];
  dataSource: MatTableDataSource<GridRow>;
  isLoadingResults = true;
  includeMatched = false;
  resultsLength = 0;
  pageSize = 5;
  selectedRow: GridRow;
  selection = new SelectionModel<GridRow>(false, []);
  progress = false;

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {
    if (this.data) {
      if (this.data.confirmRecordType) {
        this.confirmRecordType = this.data.confirmRecordType;
        this.selectedIndex = this.data.selectedIndex;
        this.sourceType = this.data.sourceType;
      }
      // If the user changes the sort order, reset back to the first page.
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          startWith({}),
          switchMap(() => {
            this.isLoadingResults = true;
            return this.fetchRecords()
              .pipe(catchError(() => observableOf(null)));
          })
        )
        .subscribe((page: GridPage) => {
          this.isLoadingResults = false;
          // @ts-ignore
          this.resultsLength = page?.totalElements;
          this.asDataSource(page.rows);
        });
    }
  }

  fetchRecords() {
    const search = 'searchQuery:' + (this.data.searchQuery || '');
    if (this.confirmRecordType === 'EMPLOYEE') {
      return this.employeeListService.getAll(this.paginator.pageIndex, this.paginator.pageSize,
        this.sort.active, this.sort.direction, search, !this.includeMatched)
        .pipe(map(e => this.mapEmployees(e)));
    } else if (this.confirmRecordType === 'VIRTUAL_EMPLOYEE') {
      return this.virtualEmployeeService.findAll(this.paginator.pageIndex, this.paginator.pageSize,
        this.sort.active, this.sort.direction, search, !this.includeMatched)
        .pipe(map(e => this.mapVirtualEmployees(e)));
    } else if (this.confirmRecordType === 'NETSUITE_EMPLOYEE') {
      return this.netsuiteEmployeeService.findAll(this.paginator.pageIndex, this.paginator.pageSize,
        this.sort.active, this.sort.direction, search, !this.includeMatched)
        .pipe(map(e => this.mapNetsuiteEmployees(e)));
    }
  }

  asDataSource(rows: GridRow[]) {
    this.gridRows.splice(0, this.gridRows.length);
    Array.prototype.push.apply(this.gridRows, rows);
    this.dataSource = new MatTableDataSource(this.gridRows);
    this.dataSource.sort = this.sort;
    this.table.renderRows();
    this.dataSource._updateChangeSubscription();
  }

  private mapEmployees(page: EmployeesPage): GridPage {
    return {
      rows: page.content.map(e => this.mapEmployee(e)),
      number: page.number,
      size: page.size,
      totalElements: page.totalElements,
      totalPages: page.totalPages
    } as GridPage;
  }

  mapEmployee(employee: Employee): GridRow {
    return {
      rarebreedId: employee.rarebreedId,
      internalId: employee.id,
      name: `${employee.firstName} ${employee.lastName}`,
      mapped: employee.employeeNetsuite !== null,
    } as GridRow;
  }

  mapVirtualEmployees(page: VirtualEmployeesPage): GridPage {
    return {
      rows: page.content.map(e => this.mapVirtualEmployee(e)),
      number: page.number,
      size: page.size,
      totalElements: page.totalElements,
      totalPages: page.totalPages
    } as GridPage;
  }

  mapVirtualEmployee(employee: VirtualEmployee): GridRow {
    return {
      rarebreedId: employee.rarebreedId,
      internalId: employee.id,
      name: employee.name,
      mapped: employee.employeeNetsuite !== null,
    } as GridRow;
  }

  mapNetsuiteEmployees(page: NetsuiteEmployeesPage): GridPage {
    return {
      rows: page.content.map(e => this.mapNetsuiteEmployee(e)),
      number: page.number,
      size: page.size,
      totalElements: page.totalElements,
      totalPages: page.totalPages
    } as GridPage;
  }

  mapNetsuiteEmployee(e: NetsuiteEmployee): GridRow {
    return {
      rarebreedId: e.rarebreedEmployeeId,
      internalId: e.originalId,
      name: `${e.firstName || '--'} ${e.lastName || ''}`,
      mapped: e.masterEmployee !== null || e.virtuaEmployee !== null || e.rarebreedEmployeeId && e.rarebreedEmployeeId.indexOf('RB') === 0,
      mappedType: e.masterEmployee !== null ? 'EMPLOYEE' : e.virtuaEmployee !== null ? 'VIRTUAL_EMPLOYEE' : null,
      mappedId: e.masterEmployee !== null ? e.masterEmployee.id : e.virtuaEmployee !== null ? e.virtuaEmployee.id : null,
    } as GridRow;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  goBack() {
    this.stepper.previous();
    this.selectedIndex = this.stepper.selectedIndex;
  }

  goForward() {
    this.stepper.next();
    this.selectedIndex = this.stepper.selectedIndex;
    this.reset();
  }

  confirmMatch() {
    this.progress = true;
    this.unmapAndMatch(this.selectedRow.internalId, this.data.originalId, this.data.systemCode, this.confirmRecordType);
  }

  unmapAndMatch(internalId, originalId, systemCode, recordType) {
    if (this.data.unmap) {
      this.unmatchedRecordsService
        .unmatchEmployee(internalId, originalId, systemCode, recordType, this.sourceType, null, null)
        .subscribe(
          result => {
            this.processMatch(internalId, originalId, systemCode, recordType);
          },
          error => {
            console.log(error);
          });
    } else  {
      this.processMatch(internalId, originalId, systemCode, recordType);
    }
  }

  processMatch(internalId, originalId, systemCode, recordType) {
    const selectedRow = this.selectedRow;
    if (selectedRow != null && selectedRow.mapped) {
      this.unmatchedRecordsService
        .unmatchEmployee(internalId, originalId, systemCode, recordType, this.sourceType, selectedRow.mappedType, selectedRow.mappedId)
        .subscribe(
          result => {
            this.matchEmployee(internalId, originalId, systemCode, recordType);
          },
          error => {
            console.log(error);
          });
    } else {
      this.matchEmployee(internalId, originalId, systemCode, recordType);
    }
  }

  matchEmployee(internalId, originalId, systemCode, recordType) {
    this.unmatchedRecordsService.matchEmployee(internalId, originalId, systemCode, recordType, this.sourceType)
      .subscribe(
        response => {
          this.progress = false;
          this.dialogRef.close(response);
          this._snackBar.open('Employee \'' + this.data.fullName + '\' was successfully matched!', 'Close', {
            duration: 5000,
          });
        },
        error => {
          console.log(error);
        });
  }

  createNewVirtualEmployee() {
    this.progress = true;
    this.virtualEmployeeService.create({name: this.data.fullName} as VirtualEmployee)
      .subscribe(
        employee => {
          this.unmapAndMatch(employee.id, this.data.originalId, this.data.systemCode, this.confirmRecordType);
        },
        error => {
          console.log(error);
        });
  }

  reset() {
    this.paginator.pageIndex = 0;
    this.paginator._changePageSize(this.paginator.pageSize);
    this.selectedRow = null;
  }

  select(row: GridRow) {
    this.selectedRow = row;
    this.selection.toggle(row);
  }

  changeIncludeMatched() {
    this.includeMatched = !this.includeMatched;
    this.reset();
  }
}
